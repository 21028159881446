import { createConfig, http } from "wagmi";
import { polygon, polygonAmoy } from "wagmi/chains";

const APP_ENV = process.env.NEXT_PUBLIC_APP_ENV;

export const wagmiConfig = createConfig({
  chains: APP_ENV === "production" ? [polygon] : [polygonAmoy],
  transports: {
    [polygon.id]: http(),
    [polygonAmoy.id]: http(),
  },
});
