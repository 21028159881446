import Head from "next/head";
import { Fragment, ReactNode } from "react";

import config from "@/config/seo_meta.json";

const BASE_URL = "https://member.discovertitanic.com/";

interface OgImage {
  url?: string;
  width?: string;
  height?: string;
  alt?: string;
}

interface Props {
  title?: string;
  canonical?: string;
  description?: string;
  robots?: string;
  openGraph?: {
    title?: string;
    type?: string;
    locale?: string;
    description?: string;
    site_name?: string;
    url?: string;
    images?: OgImage[];
  };
  children?: ReactNode;
}

const ogImage = ({ url, width, height, alt }: OgImage, index: number) => {
  // generate full URL for OG image url with base URL
  const imgUrl = BASE_URL ? new URL(url!, BASE_URL).toString() : url;

  return (
    <Fragment key={`og:image:${index}`}>
      <meta content={imgUrl} key={`og:image:url:${index}`} property="og:image" />
      <meta content={width} key={`og:image:width:${index}`} property="og:image:width" />
      <meta content={height} key={`og:image:height:${index}`} property="og:image:height" />
      <meta content={alt} key={`og:image:alt:${index}`} property="og:image:alt" />
    </Fragment>
  );
};

const SEO: React.FunctionComponent<Props> = ({
  title,
  canonical,
  description,
  openGraph,
  robots,
  children,
}) => {
  return (
    <Head>
      <link href={canonical ?? "https://member.discovertitanic.com/"} rel="canonical" />
      <link href="/favicon.ico?v=2" rel="icon" />
      <title key="title">
        {title ? `${config.titleTemplate.replace(/%s/g, title)}` : config.title}
      </title>
      <meta content="width=device-width, initial-scale=1" name="viewport" />
      <meta content={description || config.description} key="description" name="description" />
      <meta content={openGraph?.type ?? config.openGraph.type} key="og:type" property="og:type" />
      <meta
        content={openGraph?.title ?? config.openGraph.title ?? title ?? config.title}
        key="og:title"
        property="og:title"
      />
      <meta
        content={
          openGraph?.description ??
          config.openGraph.description ??
          description ??
          config.description
        }
        key="og:description"
        property="og:description"
      />
      <meta
        content={openGraph?.site_name ?? config.openGraph.site_name}
        key="og:site_name"
        property="og:site_name"
      />
      <meta content={openGraph?.url ?? config.openGraph.url} key="og:url" property="og:url" />
      {openGraph?.locale && (
        <meta content={openGraph.locale} key="og:locale" property="og:locale" />
      )}
      {openGraph?.images?.length
        ? openGraph.images.map((img, index) => ogImage(img, index))
        : ogImage(config.openGraph.images[0], 0)}
      <meta content={robots ?? "index,follow"} key="robots" name="robots" />
      <meta content={robots ?? "index,follow"} key="googlebot" name="googlebot" />
      {children}
    </Head>
  );
};

export default SEO;
