import { AnimatePresence } from "framer-motion";
import type { AppProps } from "next/app";
import Head from "next/head";
import { useRouter } from "next/router";
import React from "react";
import { RecoilRoot } from "recoil";
import "toastify-js/src/toastify.css";
import { WagmiProvider } from "wagmi";

import { NextPageWithLayout } from "./page";

import SEO from "@/components/common/SEO";
import { DynamicAuthProvider } from "@/components/Providers/dynamics";
import "@/styles/globals.css";
import { wagmiConfig } from "@/utils/evm/wagmi-config";
import { ScriptGAInit } from "@/utils/gtm";

interface AppPropsWithLayout extends AppProps {
  Component: NextPageWithLayout;
}

const App = ({ Component, pageProps }: AppPropsWithLayout) => {
  const getLayout = Component.getLayout || (page => page);
  const router = useRouter();

  React.useEffect(() => {
    document.body.classList?.remove("loading");
  }, []);

  const isNoAuthPage = router.pathname === "/asset-gen";

  const content = (
    <WagmiProvider config={wagmiConfig}>
      <AnimatePresence>{getLayout(<Component {...pageProps} />)}</AnimatePresence>
    </WagmiProvider>
  );

  return (
    <RecoilRoot>
      <SEO />
      <Head>
        <meta content="width=device-width, initial-scale=1" name="viewport" />
      </Head>
      <ScriptGAInit />
      {isNoAuthPage ? (
        <>{content}</>
      ) : (
        <DynamicAuthProvider
          onRedirectCallback={appState => {
            router.push(
              appState && appState.returnTo
                ? appState.returnTo
                : `${window.location.pathname}${window.location.search}`,
            );
          }}
        >
          {content}
        </DynamicAuthProvider>
      )}
    </RecoilRoot>
  );
};

export default App;
